// import tippy from 'tippy.js';
// import './tooltip';
import './bootstrap';
import './header';
import './backtotop';
import './contact';
import './authentication';
import './home';

import './foodmenu';
import './cart';
import './order';

