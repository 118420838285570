/**
 * cart page
 */
$(document).ready(function(){

});

$("[data-action='cart-foodmenu-remove']").click(function(){
    console.log("remove foodmenu");
    $('.js-foodmenu_wrapper[data-foodmenu_slug="'+$(this).data('foodmenu_slug')+'"]').remove();
    updateCart();
});

$("[data-action='cart-product-remove']").click(function(){
    var product_selector = '.js-foodmenu_wrapper[data-foodmenu_slug="'+$(this).data('foodmenu_slug')+'"] .js-product_wrapper[data-product_id="'+$(this).data('product_id')+'"]';
    $(product_selector).remove();
    // remove foodmenu if was last product
    if($('.js-foodmenu_wrapper[data-foodmenu_slug="'+$(this).data('foodmenu_slug')+'"] .js-product_wrapper').length == 0){
        $('.js-foodmenu_wrapper[data-foodmenu_slug="'+$(this).data('foodmenu_slug')+'"]').remove();
    }
    updateCart();
});

$("[data-action='cart-addon-product-remove']").click(function(){
    var product_selector = '.js-addon-product_wrapper[data-product_id="'+$(this).data('product_id')+'"]';
    console.log("selector");
    console.log(product_selector)
    $(product_selector).remove();
    if($('.js-addon-product_wrapper').length == 0){
        $('.js-addons_title').hide();
    }
    updateCart();
});

$("[data-action='cart-product-update-qtt']").change(function(){
    updateCart();
});

$("[data-action='cart-add-addon']").click(function(){

    var url = $(this).data('url');

    new Fancybox([{
        src: url,
        type: 'iframe',
        width: 1150
        }
    ]);
});

var show_addon_popup = 1;
$("[data-action='cart-confirm']").click(function(){

    console.log("cart-confirm");
    var form = $("#cart-form");
    $.ajax({
        url: form.data('confirm-url')+'?show_addon_popup='+show_addon_popup,
        type: 'GET',
        dataType: "json",
    }).done(function(result){
        if(result.action=='popup'){
            show_addon_popup = false;
            new Fancybox([{
                src: result.url,
                type: 'iframe',
                width: 1150
                }
            ]);
        }else{
            window.location.href = form.data('order_url');
        }

    }).fail(function(data){

    });
});

window.updateCart = function(){
    var form = $("#cart-form");
    var data = form.serialize();
    $.ajax({
        url: form.data('update-cart-url'),
        type: 'GET',
        data: data,
        dataType: "json",
    }).done(function(result){
        $('.js-cart_subtotal').html(result.data.subtotal);
        
        $('.js-cart_shipping').html(result.data.shipping_cost);
        $('.js-cart_total').html(result.data.total);
        $('.js-cart_number_of_items').html(result.data.number_of_items);
        
        var foodmenu_slugs = [];
        var cart_is_empty = true;
        // foodmenus
        $.each(result.data.foodmenus,function(index,foodmenu){
            var productcategory_ids = [];
            $.each(foodmenu.foodmenu_products,function(index2,foodmenu_product){
                $('.js-foodmenu_wrapper[data-foodmenu_slug='+foodmenu.slug+'] .js-product_price[data-product_id='+foodmenu_product.product.id+']').html(foodmenu_product.total);
                $('.js-foodmenu_wrapper[data-foodmenu_slug='+foodmenu.slug+'] select[data-product_id='+foodmenu_product.product.id+']').val(foodmenu_product.quantity);
                productcategory_ids.push(foodmenu_product.product.productcategory.id);
            });
            // remove possible unnecessary product category titles from foodmenu (removed product)
            $('.js-foodmenu_wrapper[data-foodmenu_slug='+foodmenu.slug+'] .js-productcategory_name').each(function(){
                if(!productcategory_ids.includes($(this).data('productcategory_id'))){
                    $(this).hide();
                }
            });
            foodmenu_slugs.push(foodmenu.slug);
            cart_is_empty = false;
        });
        // addon products
        $.each(result.data.products,function(index,addon_product){
            $('.js-addon_product_price[data-product_id='+addon_product.product.id+']').html(addon_product.total);
            cart_is_empty = false;
        });
        console.log("cart_is_empty", cart_is_empty);
        if(cart_is_empty){
            console.log("hiding cart");
            $('.js-cart_wrapper').hide();
            $('.js-empty_cart_wrapper').show();
        }

    }).fail(function(data){

    });
}

/**
 * CART product popup page
 */
var product_edit = null;

$(document).on('click',"[data-action='addon-product-update-qtt']",function(e){
    var product_id = $(this).data('product_id');
    var new_qtt = $('select[name="qtt"]').val();
    var selector = 'select[name="product['+product_id+']"]';
    if($(selector, window.parent.document).length > 0){
        console.log("product selector found in foodmenu. updating qtt");
        // update product qtt
        $(selector, window.parent.document).val(new_qtt);
        window.parent.updateCart();
        window.parent.Fancybox.close();
    }else{
        // add product
        var form = $("#product-form");
        var url = form.data('update-product-url');
        addProductToCart(form.serialize(), url);
    }

});

/**
 * CART product addon category (with product list) popup page
 */
$(document).on('click',"[data-action='addon-category-product-update-qtt']",function(e){
    e.preventDefault();
    var product_id = $(this).data('product_id');
    var new_qtt = $('input[name="qtt_'+product_id+'"]').val();
    var selector = 'select[name="product['+product_id+']"]';
    if($(selector, window.parent.document).length > 0){
        // update product qtt
        $(selector, window.parent.document).val(new_qtt);
        window.parent.updateCart();
        window.parent.Fancybox.close();
    }else{
        // add product
        var data = 'product['+product_id+']='+new_qtt;
        var url = $('input[name="url_'+product_id+'"]').val();
        console.log("adding product. URL");
        console.log(url);
        addProductToCart(data, url);
    }
});

function addProductToCart(data, url){

    $.ajax({
        url: url,
        type: 'GET',
        data: data,
        dataType: "json",
    }).done(function(result){
        console.log("addProductToCart ajax done");
        window.parent.location.reload();
        window.parent.Fancybox.close();
    }).fail(function(data){
        console.log("addProductToCart ajax fail");
        console.log(url);
    });
}

$(document).on('click',"[data-action='skip_addons']",function(e){
    window.parent.show_addon_popup = false;
    window.parent.$("[data-action='cart-confirm']").click();
});