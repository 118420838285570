$('[data-action="checkPostalCode"]').click(function(){
    var url = $('form.js-check_postal_code').data('url');
    var postal_code = $('form.js-check_postal_code [name="postal_code"]').val();
    console.log(url);
    console.log(postal_code);
    $('.js_search_fb').html('').hide();
    if(postal_code.length > 0){
        $.ajax({
            url: url,
            type: 'GET',
            data: {postal_code: postal_code},
            dataType: "json",
        }).done(function(result){
            $('.js_search_fb').html(result.msg).show();
        }).fail(function(data){
        });   
    }
});