$(window).scroll(function() {
  let header = $('.header');
  if($(this).scrollTop()>0){
    $(header).addClass('js-sticky');
  } else {
    $(header).removeClass('js-sticky');
  }
});

let langCurrent = $('.lang-sel__current');
let langToggle = $('.lang-sel__toggle');

$(langCurrent).click(function(){
  $(langToggle).toggleClass('js-show');
  $(langCurrent).toggleClass('js-show');
});

let menuToggle = $('.m-toggle');
let menu = $('.menu');
let menuHamb = $('.m-hamb');
let header = $('.header');

$(menuToggle).click(function(){
  $(this).toggleClass('js-open');
  $(menuHamb).toggleClass('js-open');
  $(header).toggleClass('js-open');
});