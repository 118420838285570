/**
 * order page
 */

function validateAddress(){
    $('.js-other_address_error').hide();
    $('.js-other_address_error_postal_code').hide();
    console.log("validate address");
    var postal_code_to_validate;
    if($('[name="address_type"]:checked').val() == "other"){
        postal_code_to_validate = $("[name='other_postal_code']").val();
        if($("[name='other_address']").val() == "" || $("[name='other_postal_code']").val() == "" || $("[name='other_city']").val() == ""){
            $('.js-other_address_error').show();
            return false;
        }
    }else{
        postal_code_to_validate = $("[name='contact_postal_code']").val();   
    }
    console.log("postal code to validate");
    console.log(postal_code_to_validate);
    console.log(valid_postal_codes[0]);
    if($.inArray(parseInt(postal_code_to_validate),valid_postal_codes)==-1){
        $('.js-other_address_error_postal_code').show();
        return false;
    }
    return true;
}

function validateEvent(){
    $('.js-event_error').hide();
    if($("[name='date']").val() == "" || $("[name='time']").val() == "" || $("[name='pax']:selected").val() == "" || $("[name='order_type']:selected").val() == ""){
        $('.js-event_error').show();
        return false;
    }
    return true;
}

$("[data-action='order-other-direction']").change(function(){
    if($(this).val() == "other"){
        $(".js-other_address").show();
    }else{
        $(".js-other_address").hide();
        $('.js-other_address_error').hide();
        $('.js-other_address_error_postal_code').hide();
    }
});

$("[data-action='order-go-to-event']").click(function(){
    $('.js-other_address_error').hide();
    $('.js-other_address_error_postal_code').hide();
    var val_address = validateAddress();
    if(val_address){
        $(this).hide();
        $('.js-event_wrapper').show();
    }
});

$("[data-action='order-go-to-payment']").click(function(){
    var validate_address = validateAddress();
    var validate_event = validateEvent();
    if(validate_address && validate_event){
        if($('input[name="order_type"]:checked').val() == 'budget'){
            orderSubmit();
        }else{
            $(this).hide();
            $('.js-payment_wrapper').show();
        }
    }
});

$("[data-action='order-submit']").click(function(){
    var validate_address = validateAddress();
    var validate_event = validateEvent();
    if(validate_address && validate_event){
        orderSubmit();
    }
});

function orderSubmit(){
    var form = $(".js-order_form");
    form.submit();
    // console.log("submit here");
    // console.log(form.serialize());

}


$(document).on('change',"select[data-action='addon-product-qtt']",function(e){
    console.log("changed");
    var product_id = $(this).data('product_id');
    updateAddonProductQtt(product_id, $(this).val());
});

function updateAddonProductQtt(){

    console.log("order.js updateAddonProductQtt");

    var form = $("#product-form");
    var data = form.serialize();

    $.ajax({
        url: form.data('update-product-url'),
        type: 'GET',
        data: data,
        dataType: "json",
    }).done(function(result){
        $('.js-product_price').html(result.data.total);
    }).fail(function(data){

    });
}
